<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            :placeholder="constants.partnerSku.SEARCH_TEXT"
          />
          <template v-if="showTradingPartner">
          <el-select class="me-2 w-250px" v-model="searchParams.tradingPartnerId" :placeholder="constants.general.SELECTTRADINGPARTNER" size="large">
            <el-option value="">{{constants.general.SELECTTRADINGPARTNER}}</el-option>
            <el-option v-for="partner in tradingPartners" :value="partner.trading_partner_id" :key="partner" :label="partner.name">
              {{partner.name}}
            </el-option>
          </el-select>
          </template>
        </div>
        <el-tooltip class="box-item" effect="dark" :content="constants.general.SEARCH" placement="top">
          <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" :content="constants.general.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
      >
        <template v-slot:cell-id="{ row: data }">
          {{ data }}
        </template>
        <template v-slot:cell-platform="{ row: data }">
          {{ data.partner != null ? data.partner.get_platform.platform_name : '-' }}
        </template>
        <template v-slot:cell-partner_sku="{ row: data }">
          <span class="sku-font">{{ data.partner_sku }}</span>
        </template>
        <template v-slot:cell-sku="{ row: data }">
          <span class="sku-font">{{ data.product_sku.sku }}</span>
        </template>
        <template v-slot:cell-name="{ row: data }">
          <template v-if="permission.isProductViewAllowed">
            <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="$router.push({ name: 'view-product', params: { id: data.product_sku.product_id } })">{{ data.product_sku.get_product.name }}</a>
          </template>
          <template v-else>
              <p>{{ data.product_sku.get_product.name }}</p>
          </template>
        </template>
        <template v-slot:cell-size="{ row: data }">
          {{ data.product_sku.get_size.size }}
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useRoute, useRouter } from "vue-router";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import useEventBus from '../../composable/useEventBus';
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import { useStore } from "vuex";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";

export default {
  name: "trading-partner-listing",
  components: {
    Datatable
  },
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    let total = ref(0);
    const store = useStore();
    const tableData = ref([]);
    const loading = ref(false);
    const updateProps = ref(null);
    const route = useRoute();
    const router = useRouter();
    const tradingPartners = ref([]);
    const showTradingPartner = ref(true);
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const constants = globalConstant;
    const noDataFound = ref(globalConstant.general.LOADING);
    const permission = reactive({
      isProductViewAllowed: false,
    })
    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "back"){
        router.push('/trading-partners');
      }
    })

    //Get all active trading partners
    const getTradingPartners = async () => {
      loading.value = true;
      await ApiService.query("trading-partner")
      .then(({ data }) => {
        if (data.data) {
          tradingPartners.value = data.data;
        } else {
          tradingPartners.value = [];
        }
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        tradingPartners.value = [];
        loading.value = false;
      });
    }

    const searchParams = reactive({
      search: '',
      recordsPerPage: Number(JSON.parse(window.localStorage.getItem('site_config'))['per_page']),
      pageNub: 1,
      tradingPartnerId: ''
    });

    const tableHeader = ref([
      {
        name: constants.general.SRNO,
        key: "index",
        sortable: false,
      },
      {
        name: constants.orders.PLATFORM,
        key: "platform",
        sortable: false,
      },
      {
        name: constants.product.PARTNER_SKU,
        key: "partner_sku",
        sortable: true,
      },
      {
        name: constants.partnerSku.SYSTEM_SKU,
        key: "sku",
        sortable: true,
      },
      {
        name: constants.product.PRODUCT_NAME,
        key: "name",
        sortable: true,
      },
      {
        name: constants.product.SIZE,
        key: "size",
        sortable: true,
      },
    ]);

    //Get all presses
    const getTradingPartnerSku = async () => {
      loading.value = true;
      await ApiService.query("partner-sku", { params: searchParams })
        .then(({ data }) => {
          if (data.data.data.length) {
            tableData.value.splice(0, tableData.value.length, ...data.data.data);
            total.value = data.data.total;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
          } else {
            tableData.value = [];
            total.value = 0;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, constants.general.NO_DATA_FOUND);
          }
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{"name": formatText('trading-partners'), "path": "trading-partners"}, { name: formatText(route.name), active: true }]);
          loading.value = false;
        })
        .catch((error) => {
          tableData.value = [];
          loading.value = false;
        });
    };

    //Page size change event
    const handleSizeChange = (number) => {
      searchParams.recordsPerPage = number;
      handleCurrentChange(1);
    };

    //Page change event
    const handleCurrentChange = (number) => {
      searchParams.pageNub = number;
      getTradingPartnerSku();
    };

    //Handle search event
    const handleSearch = () => {
      handleCurrentChange(1);
    };

    //Reset search value
    const resetSearch = () => {
      searchParams.search = "";
      searchParams.tradingPartnerId = route.params.id > 0 ? Number(route.params.id) : '';
      getTradingPartnerSku();
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name": constants.general.BACK , 'action': 'back','class': 'btn-primary','isLoader':false, 'iconClass':"bi bi-arrow-left fs-4" }]);
    }

    //Set breadcrumbs and get the presses
    onMounted(async () => {
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("viewProduct") || role == "Super Admin")
        permission.isProductViewAllowed = true;

      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": formatText('trading-partners'), "path": "trading-partners"}, { name: formatText(route.name), active: true }]);
      await getTradingPartners();
      if(route.params.id != "undefined" && route.params.id > 0){
        searchParams.tradingPartnerId = Number(route.params.id);
      }
      await getTradingPartnerSku();
      generateHeaderToolbarButtons();
    });

    return {
      tableData,
      getTradingPartnerSku,
      total,
      loading,
      searchParams,
      handleSearch,
      handleSizeChange,
      handleCurrentChange,
      resetSearch,
      tableHeader,
      noDataFound,
      updateProps,
      constants,
      tradingPartners,
      getTradingPartners,
      showTradingPartner,
      permission
    };
  },
};
</script>
<style lang="scss">
@import 'https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap" rel="stylesheet';
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.sku-font {
  font-family: 'Roboto Mono', monospace;
}
</style>